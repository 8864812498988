import { createAction, props } from '@ngrx/store';
import { LanguageModel } from 'app/main/admin/languages/models/language.model';
 
export enum LanguageActionTypes {
    getLanguages = '[Language] Data',
    getLanguage = '[Language] Get Language Data',
    addLanguage = '[Language] Add Language',
    updateLanguage = '[Language] Update Language',
    deleteLanguage = '[Language] Delete Language',
}
export const getLanguages = createAction(
    LanguageActionTypes.getLanguages
);
export const getLanguage = createAction(
    LanguageActionTypes.getLanguage,
    props<{id: number}>()
);
export const addLanguage = createAction(
    LanguageActionTypes.addLanguage,
    props<LanguageModel>()
);
export const updateLanguage = createAction(
    LanguageActionTypes.updateLanguage,
    props<LanguageModel>()
);
export const deleteLanguage = createAction(
    LanguageActionTypes.deleteLanguage,
    props<{id: number}>()
);