import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {catchError, map, concatMap, withLatestFrom, filter, mergeMap, switchMap, share} from 'rxjs/operators';
import { of } from 'rxjs';
import * as languageActions from '../actions/language.actions';
import {Store} from "@ngrx/store";
import {State} from "../../../../store";
import {NotificationService} from "../../../../services/notification.service";
import { languagesEntity } from 'app/main/admin/store/reducers';
import { LanguagesService } from 'app/main/admin/languages/services/languages.service';

@Injectable()
export class LanguageEffects {
    updatelanguage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(languageActions.updateLanguage),
            concatMap((action) =>
                this.languageService.update(action).pipe(
                    map(response => {
                        this.notificationService.showSuccess('Saved', 'language Saved Successfully');
                        return languagesEntity.actions.updateOne({update: {id: response.response.id, changes:response.response}});
                    }),
                    catchError(error => {
                        return of(languagesEntity.actions.updateAdditional({updates: {error}}));
                    })
                )
            ),
            share()
        );
    });
    deletelanguage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(languageActions.deleteLanguage),
            concatMap((action) =>
                this.languageService.delete(action.id.toString()).pipe(
                    map(data => {
                        this.notificationService.showSuccess('Deleted', 'language Deleted Successfully');
                        return languagesEntity.actions.removeOne({id: action.id});
                    }),
                    catchError(error => {
                        return of(languagesEntity.actions.updateAdditional({updates: {error}}));
                    })
                )
            ),
            share()
        );
    });
  constructor(
      private actions$: Actions,
      private languageService: LanguagesService,
      private notificationService: NotificationService,
      private store: Store<State>
  ) {}
}