import { Injectable } from '@angular/core';
import { environment } from "../../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "../../../../services/app.settings.service";
import { Observable } from "rxjs";
import { BaseResultModel } from "../../../../models";
import { ResponseLanguageData } from "../models/response-language-data";
import { PostLanguage } from "../models/post-language";

@Injectable({
    providedIn: 'root'
})
export class LanguagesService {
    private readonly uri: string = environment.apiUrl;
    private endpoint = '/system';

    constructor(
        private http: HttpClient,
        configService: AppConfigService,
    ) {
        if (configService) {
            this.uri = configService.getAPIUrl();
        }
    }

    getLanguages(): Observable<BaseResultModel<ResponseLanguageData[]>> {
        return this.http.get<BaseResultModel<ResponseLanguageData[]>>(this.uri + this.endpoint + '/languages/2');
    }

    update(language: PostLanguage): Observable<BaseResultModel<ResponseLanguageData>> {
        return this.http.put<BaseResultModel<ResponseLanguageData>>(this.uri + this.endpoint + '/language', language);
    }
    public delete(id: string): Observable<BaseResultModel<boolean>> {
        const endpoint = `${this.uri}${this.endpoint}/language/${id}`;
        return this.http.delete<BaseResultModel<boolean>>(`${endpoint}`);
    }
    // Add the deactivateLanguages method
    deactivateLanguages(): Observable<BaseResultModel<boolean>> {
        return this.http.get<BaseResultModel<boolean>>(this.uri + this.endpoint + '/languages/deactivate');
    }
}
