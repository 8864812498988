import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { RefDataResolver } from 'app/resolvers/ref.data.resolver';
import { TpAuthService } from 'app/services/auth/tp-auth.service';
import {MyMissingTranslationHandler, MyTranslateLoader} from 'app/services/localization-loader.service';
import { QuillModule } from 'ngx-quill';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { LOCAL_STORAGE, SESSION_STORAGE } from 'ngx-webstorage-service';
import { AuthGuard } from './guards/auth.guard';
import { AccountCategoryService } from './main/admin/accounting/account-categories/services/account-categories.service';
import { BankAccountService } from './main/admin/accounting/bank-accounts/services/bank-account.service';
import { AppConfigService } from './services/app.settings.service';
import { CustomHttpInterceptorService } from './services/auth/custom-http-interceptor.service';
import { CommonService } from './services/general/common.service';
import { EmailManagerService } from './services/general/email-manager.service';
import { RefDataService } from './services/general/refData.service';
import { UserService } from './services/general/user.service';
import { FormatSearchCriteriasService } from './services/helpers/format-search-criterias.service';
import { NotificationService } from './services/notification.service';
import { CORE_LOCAL_STORAGE, CORE_SESSION_STORAGE, TPStorageService } from './services/storage/tp-storage.service.ts';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TpModalModule } from './components/tp-modal/tp-modal.module';
import { TpButtonModule } from './components/tp-button/tp-button.module';
import { ApirequestService } from './services/general/api-service.service';
import { GlobalConfiguration } from './models/general/global-configuration';
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { reducers, metaReducers } from './store';
import {
    UnitEffects, ClientEffects, ServiceEffects, ClientContactEffects, ClientNoteEffects, ClientFileEffects,
    ClientRateEffects, ResourceEffects, ResourceRateEffects, ResourceNoteEffects, DropdownsEffects, ProjectEffects,
    WorkflowEffects,LanguageEffects
} from './main/pm/store/effects';
import { Events } from "./models/events/events";
import { GlobalEffects } from './store/effects';
import { pmPortalStateFeatureKey } from "./main/pm/store";
import { pmPortalMetaReducers, pmPortalReducers } from "./main/pm/store";
import { LayoutService } from "./layout/layout.service";
import { MessagesService } from './services/messages.service';
import { NgxsModule } from '@ngxs/store';
import { RefDataState } from './ngxsState/ref-data/ref-data.state';
import { PMModule } from './main/pm/pm.module';

const appRoutes: Routes = [
    {
        path: '',
        loadChildren: () => import('./main/redirect/redirect.module').then(m => m.RedirectModule),
    },
    {
        path: 'pm',
        loadChildren: () => import('./main/pm/pm.module').then(m => m.PMModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'client',
        loadChildren: () => import('./main/client/client.module').then(m => m.ClientModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'resource',
        loadChildren: () => import('./main/resource-view/resource.module').then(m => m.ResourceViewModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'email-logs',
        loadChildren: () => import('./main/admin/emails-log/emails-log.module').then(m => m.EmailsLogModule),
    },
    {
        path: 'absences',
        loadChildren: () => import('app/main/common/user-absences/user-absences.module').then(m => m.UserAbsencesModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'products',
        loadChildren: () => import('app/main/products/products.module').then(m => m.ProductsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'login',
        loadChildren: () => import('app/main/login/login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'signup',
        loadChildren: () => import('app/main/signup/signup.module').then(m => m.SignupModule),
    },
    {
        path: 'sign-in/:tkn',
        loadChildren: () => import('app/main/sign-in-idp/sign-in-idp/sign-in-idp.module').then(m => m.SignInIdpModule),
    },
    {
        path: 'quote-download/:tkn',
        loadChildren: () => import('./main/common/quote-download/quote-download.module').then(m => m.QuoteDownloadModule),
    },
    {
        path: 'preview/:tkn',
        loadChildren: () => import('./main/common/preview/preview.module').then(m => m.PreviewModule),
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./main/common/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    },
    {
        path: 'reset-password/:tkn',
        loadChildren: () => import('./main/common/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    },
    {
        path: 'resource-job-acceptance/:tkn',
        loadChildren: () => import('app/main/common/resource-job-acceptance/resource-job-acceptance.module').then(m => m.ResourceJobAcceptanceModule),
    },
    {
        path: 'unauthorized',
        loadChildren: () => import('./main/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule),
    },
    {
        path: '**',
        loadChildren: () => import('./main/not-founded/not-founded.module').then(m => m.NotFoundedModule),
        pathMatch: 'full'
    },
];

export function initializeApp(messagesService: MessagesService) {
    return () => messagesService.initializeApp();
}

export function TranslateLoaderFactory(http: HttpClient) {
    return new MyTranslateLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        HttpClientModule,
        NgIdleKeepaliveModule.forRoot(),
        NgxsModule.forRoot([RefDataState]),
        RouterModule.forRoot(appRoutes, {
            relativeLinkResolution: 'legacy',
            anchorScrolling: 'enabled',
            onSameUrlNavigation: 'reload',
            useHash: false,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslateLoaderFactory,
                deps: [HttpClient],
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: MyMissingTranslationHandler,
            }
        }),
        MatMomentDateModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        QuillModule.forRoot(),
        LayoutModule,
        NgxSpinnerModule,
        TpModalModule,
        TpButtonModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
        EffectsModule.forRoot([
            GlobalEffects,
        ]),
        StoreModule.forFeature(pmPortalStateFeatureKey, pmPortalReducers, { metaReducers: pmPortalMetaReducers }),
        EffectsModule.forFeature([
            ProjectEffects, DropdownsEffects,
            ClientEffects, ClientContactEffects, ClientNoteEffects, ClientFileEffects, ClientRateEffects,
            ResourceEffects, ResourceRateEffects, ResourceNoteEffects,
            ServiceEffects, UnitEffects, WorkflowEffects,LanguageEffects
        ]),
        StoreRouterConnectingModule.forRoot(),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
    ],
    providers: [
        TpAuthService,
        ApirequestService,
        FormatSearchCriteriasService,
        UserService,
        EmailManagerService,
        NotificationService,
        CommonService,
        RefDataResolver,
        AccountCategoryService,
        BankAccountService,
        AppConfigService,
        TranslateService,
        RefDataService,
        GlobalConfiguration,
        LayoutService,
        Events,
        TPStorageService,
        { provide: CORE_LOCAL_STORAGE, useExisting: LOCAL_STORAGE },
        { provide: CORE_SESSION_STORAGE, useExisting: SESSION_STORAGE },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomHttpInterceptorService,
            multi: true,
        },
        MessagesService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [MessagesService],
            multi: true
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }