export class LoadRefData
{
    static readonly type = '[RefDataState] Load Ref Data';
    constructor(public forceRefresh: boolean) {}
}

export class ProjectStatus
{
    static readonly type = '[RefDataState] Project Status';
    constructor() {}
}

export class ProjectSecondaryStatus 
{
    static readonly type = '[RefDataState] Project Secondary Status';
    constructor() {}
}

export class JobStatus
{
    static readonly type = '[RefDataState] Job Status';
    constructor() {}
}

export class QuoteStatus
{
    static readonly type = '[RefDataState] Quote Status';
    constructor() {}
}

export class QuoteSecondaryStatus 
{
    static readonly type = '[RefDataState] Quote Secondary Status';
    constructor() {}
}

export class QuoteJobStatus 
{
    static readonly type = '[RefDataState] Quote Job Status';
    constructor() {}
}

export class QuoteJobSecondaryStatus 
{
    static readonly type = '[RefDataState] Quote Job Secondary Status';
    constructor() {}
}

export class ProjectCategoryTags 
{
    static readonly type = '[RefDataState] Project Category Tags';
    constructor() {}
}

export class ProjectSectorTags 
{
    static readonly type = '[RefDataState] Project Sector Tags';
    constructor() {}
}
