import { Action, createReducer, on } from '@ngrx/store';
import {createEntity, EntityAdditional, entityInitial} from "../../../../store/models";
import {ResponseLanguageData} from "../../languages/models/response-language-data";
import {adminStateFeatureSelector} from "../features";

export const languagesFeatureKey = 'languages';
export const languagesEntity= createEntity<ResponseLanguageData, EntityAdditional>(
    adminStateFeatureSelector,
    languagesFeatureKey,
    entityInitial({}),
);
